<template>
  <v-card flat>
    <v-toolbar flat dense class="grey lighten-5">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-divider vertical inset class="d-none d-md-flex mx-2" />
      <v-toolbar-title class="py-3 px-0">
        <span>
          {{ $t(group.name) }}
        </span>
      </v-toolbar-title>
      <v-spacer />
      <v-chip class="px-2" small label v-if="isReadOnly" color="yellow">
        <v-avatar left>
          <v-icon small>mdi-alert</v-icon>
        </v-avatar>
        {{ $t("general.readonly") }}
      </v-chip>
      <v-divider vertical inset class="d-none d-md-flex mx-2" />
      <v-btn
        :disabled="isReadOnly || !items || items.length === 0"
        small
        text
        color="success"
        class="mx-2"
        @click="save"
      >
        <v-icon>mdi-content-save</v-icon>
        <span class="text--secondary">{{ $t("general.save") }}</span>
      </v-btn>
    </v-toolbar>

    <v-card-text class="overflow-y-auto px-0 py-0" :style="viewHeight()">
      <v-form ref="form" v-model="valid" class="py-5 px-5">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="item.name"
              :counter="200"
              :rules="rules.name"
              :label="$t('general.name')"
              required
              dense
              :readonly="isReadOnly"
            ></v-text-field>

            <v-text-field
              v-model="item.nameUk"
              :counter="200"
              :rules="rules.nameUk"
              :label="$t('general.nameUk')"
              required
              dense
              :readonly="isReadOnly"
            ></v-text-field>

            <br />
            <div class="d-flex justify-end">
              <v-btn
                v-if="!editMode"
                :disabled="isReadOnly || !valid"
                :dark="!isReadOnly && valid"
                color="primary"
                depressed
                @click="add"
              >
                <v-icon>mdi-plus-circle</v-icon>
                Add
              </v-btn>
              <v-btn
                v-if="editMode"
                :disabled="isReadOnly || !valid"
                :dark="!isReadOnly && valid"
                color="primary"
                depressed
                @click="update"
              >
                <v-icon>mdi-lead-pencil</v-icon>
                Update
              </v-btn>
              <v-btn v-if="editMode" depressed @click="cancelEdit" class="ml-2">
                <v-icon>mdi-close</v-icon>
                Cancel
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <v-card>
              <v-sheet tile height="40" color="primary" class="pa-3">
                <p class="v-label theme--light white--text">
                  {{ $t("settings.dataTypes.items") }}
                </p>
              </v-sheet>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :placeholder="$t('general.search')"
                clearable
                class="mx-2"
              >
              </v-text-field>
              <v-list dense>
                <v-list-item-group color="primary">
                  <div v-for="(it, i) in paginated" :key="i">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-btn
                          small
                          icon
                          @click="it.id > 0 ? edit(it.id) : edit(it.tid)"
                        >
                          <v-icon color="primary">
                            mdi-lead-pencil
                          </v-icon>
                        </v-btn>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          :class="it.deleted ? 'text-deleted red--text' : ''"
                          v-text="it.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          :class="it.deleted ? 'text-deleted red--text' : ''"
                          v-text="it.nameUk"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          small
                          icon
                          @click="
                            it.id > 0
                              ? toggleDelete(it.id)
                              : toggleDelete(it.tid)
                          "
                          :disabled="isReadOnly"
                        >
                          <v-icon v-if="!it.deleted" color="red lighten-1"
                            >mdi-close-circle</v-icon
                          >
                          <v-icon v-if="it.deleted" color="primary"
                            >mdi-undo-variant</v-icon
                          >
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="i < paginated.length - 1"></v-divider>
                  </div>
                </v-list-item-group>
              </v-list>
              <div class="text-center">
                <v-pagination
                  v-model="page"
                  :total-visible="pages > 7 ? 7 : pages"
                  :length="pages"
                  circle
                ></v-pagination>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <br />
        <v-divider />
        <v-btn
          :disabled="isReadOnly || !items || items.length === 0"
          :dark="items && items.length > 0"
          color="success"
          class="mr-4"
          tile
          depressed
          @click="save"
        >
          <v-icon>mdi-content-save</v-icon>
          {{ $t("general.save") }}
        </v-btn>

        <v-btn
          :disabled="isReadOnly"
          color="primary"
          tile
          depressed
          @click="reset"
        >
          <v-icon>mdi-undo-variant</v-icon>
          {{ $t("general.undo") }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";

export default {
  name: "TypeForm",
  props: {
    data: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      search: "",
      items: [],
      item: {},
      valid: false,
      editMode: false,
      originalState: { id: 0 },
      rules: {
        name: [(v) => !!v || "Name is required"],
        nameUk: [(v) => !!v || "Ukranian-name is required"],
      },
    };
  },
  watch: {
    data: {
      handler: function(n) {
        if (n && n.length > 0) {
          this.items = [...n];
          this.originalState = [...n];
        }
      },
    },
  },
  computed: {

    filtered() {
      if (this.search) {
        return this.items.filter((f) =>
          f.name.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return this.items;
      }
    },
    paginated() {
      let start = 0;
      if (this.page <= this.pages) {
        start = (this.page - 1) * 20;
      }
      const end = start + 20;
      return this.filtered.slice(start, end);
    },
    pages() {
      return Math.ceil(this.filtered.length / 20);
    },
    changed() {
      return this.items && !this.compareObjects(this.items, this.originalState);
    },
    routeParam() {
      return this.$route.params.route;
    },
  },
  methods: {
    add() {
      const maxid = _(this.items).maxBy("id").id;
      this.item.id = 0;
      this.item.tid = maxid + 1;
      this.item.type = this.group.type;
      this.items.push(this.item);
      this.item = {};
      this.editMode = false;
      this.$refs.form.reset();
    },
    edit(idVal) {
      this.editMode = true;
      const { id, name, nameUk, srn, type, tid, updated } = this.items.find(
        (f) => (f.id > 0 && f.id === idVal) || (f.tid > 0 && f.tid === idVal)
      );
      this.item = { id, name, nameUk, srn, type, tid, updated, deleted: false };
    },
    update() {
      const i = this.items.findIndex(
        (f) =>
          (f.id > 0 && f.id === this.item.id) ||
          (f.tid > 0 && f.tid === this.item.tid)
      );

      if (i >= 0) {
        this.$set(this.items, i, { ...this.item, updated: true });
      }

      this.item = {};
      this.editMode = false;
      this.$refs.form.reset();
    },
    cancelEdit() {
      this.item = {};
      this.editMode = false;
      this.$refs.form.reset();
    },
    toggleDelete(id) {
      const i = this.items.findIndex(
        (f) => (f.id > 0 && f.id === id) || (f.tid > 0 && f.tid === id)
      );

      if (i >= 0) {
        const it = this.items[i];
        if (it.id > 0) {
          const { id, name, nameUk, srn, type } = it;
          this.$set(this.items, i, {
            id,
            name,
            nameUk,
            srn,
            type,
            deleted: !it.deleted,
          });
        } else if (it.tid > 0) {
          this.items.splice(i, 1);
        }
      }
    },
    save() {
      this.$emit("save", {
        items: this.items.filter((f) => f.deleted || f.updated || f.id === 0),
        typeGroup: this.group.type,
        route: this.routeParam,
      });
    },
    reset() {
      this.items = [...this.originalState];
    },
  },
};
</script>

<style scoped>
.text-deleted {
  text-decoration: line-through;
}
</style>
