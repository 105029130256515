<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <type-form
        :data="getTypesByGroup(this.typeGroup.type)"
        :group="typeGroup"
        @save="save"
      />
    </v-card>
  </v-container>
</template>

<script>
import TypeForm from "./TypeForm";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TypeEditor",
  components: {
    TypeForm,
  },
  data() {
    return {
      typeGroup: null,
    };
  },
  created() {
    this.typeGroup = this.getTypeGroup(this.routeParam);
  },
  mounted() {
    this.loadTypes().then(() => {
      this.refreshBreadcrumb();
    });
  },
  computed: {
    ...mapGetters("types", ["getTypeGroup", "getTypesByGroup"]),

    routeParam() {
      return this.$route.params.route;
    },
  },
  methods: {
    ...mapActions("types", ["loadTypes", "saveType"]),
    ...mapActions("ui", ["setBreadcrumbs"]),

    refreshBreadcrumb() {
      const updatedBreadcrumbs = this.$route.meta.breadcrumb.map((item) => {
        if (item.dynamic && this.typeGroup) {
          return {
            ...item,
            name: this.typeGroup.name,
          };
        }
        return item;
      });
      this.setBreadcrumbs(updatedBreadcrumbs);
    },
    save({ typeGroup, items }) {
      if (this.notEmptyArray(items)) {
        this.saveType({ typeGroup, items: items.map((m) => ({...m, typeGroup})) });
        this.$router.go(-1);
      }
    },
  },
};
</script>
